import React from 'react'
import classes from './PMPblog.module.css'
import Container from '../../UI/Container/Container'
import Heading from '../../UI/Heading/Heading'
import { Link } from 'gatsby'
import posts from '../../../../data/blog/posts'
import SEO from '../../seo'

const PMPblog = () => {
  return (
    <Container>
      <SEO
        title="PMP Blog | Project Management Articles | PMP Exam Tips"
        keywords={[
          `PMP`,
          `PMP Blog`,
          `Project Management Articles`,
          `PMP Exam Tips`,
          `Project Management Experience`
        ]}
        description="Project Management Articles. PMP Blog Covers Popular Topics of Project Management. Prepare for the PMP Exam and PMP Certification. Manage your Projects More Effectively. Share Project Management Experience."
      ></SEO>
      <Heading text="PMP Blog" />
      <p>
        This blog is mainly about PMP and Project Management, but not only.
        Please <Link to="/project-manager-contact">contact us</Link> to suggest
        a post.
      </p>
      <div className={classes.Posts}>
        {posts.map((post, i) => {
          return (
            <div className={classes.Post} key={i}>
              <Link to={`/pmp-blog/${post.link}`}>
                <div>
                  <h2 className={classes.PostTitle}>{post.title}</h2>
                </div>
                <div className={classes.PostImage}>{post.images[0]}</div>
              </Link>
            </div>
          )
        })}
      </div>
    </Container>
  )
}

export default PMPblog
